<template>
  <div class="wrapper">
    <AppHeader />
    <Suspense>
      <template #default>
      <NotFounds />
      </template>
      <template #fallback>
        <Loading />
      </template>
    </Suspense>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
import { defineAsyncComponent, Suspense } from "vue";
import Loading from "@/components/Spinners/Loading.vue";
// import NotFounds from "@/components/Pages/NotFounds.vue";
const NotFounds = defineAsyncComponent(() =>
  import('@/components/Pages/NotFounds.vue')
)
export default {
   name: 'NotFound',
   components: {
    AppHeader,
    AppFooter,
    NotFounds,
    Loading,
    Suspense
  },
}
</script>

<style>

</style>